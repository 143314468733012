<template>
  <span>
    <v-btn v-if="defaultBtn" class="teal-color" dark small @click="pickFile">
      {{ !image_path && !uploadedImage ? "Upload Image" : "Change Image" }}
      <v-icon dark right>{{
          !image_path && !uploadedImage ? "mdi-plus-circle" : "mdi-pencil"
        }}</v-icon>
    </v-btn>
    <div class="d-flex " style="height: 100%; position: relative" >
      <v-btn
          v-if="
          (uploadedImage != null && defaultBtn == false && !isB2cCarousel) ||
            isRemoveIcon
        "
          absolute
          color="error"
          fab
          x-small
          @click="cancel"
          style="position: absolute; top: 0 ; right: 0"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-hover v-if="defaultBtn == false" v-slot="{ hover }">
        <div v-if="uploadedImage === null && !image_path" class="min-w-full">
          <div class="img-box" @click="pickFile" :style="{'background':bg_color}">
            <h3 class="text-sm font-medium m-b-2">{{ text }}</h3>
            <v-btn elevation="0" style="font-size: 10px" >
              Upload Image
            </v-btn>
              <small style="font-size: 8px ; font-weight: 400" v-if="messagePosition=='inside'" class="mt-4 text-center ml-1 mr-1">
            {{messageText}}
          </small>
          </div>
          <small style="font-size: 7px ; font-weight: 400" v-if="messagePosition=='outside'">
            {{messageText}}

          </small>
        </div>

        <v-img v-else :aspect-ratio="aspect_ratio"  :src="getImage()" :contain="contain" class="rounded-3" style="height: 100%">
          <v-btn
              v-if="deleteUploadedImage"
              absolute
             class="shadow-0"
              fab
              x-small
              @click="deleteImage"
              style="position: absolute; top: 0 ; right: 0"
          >
        <v-icon>mdi-close</v-icon>
      </v-btn>
          <v-row
              v-if="hover"
              align="center"
              class="fill-height ma-0 hoverImage"
              justify="center"
              style="cursor: pointer"
              @click="pickFile"
          >
            <div align="center" class="white--text" justify="center">
              <v-icon color="#fff" large>mdi-pencil</v-icon>
              <div class="pa-2 .title">{{ text }}</div>
            </div>
          </v-row>
        </v-img>
      </v-hover>
      <input
          ref="image"
          accept="image/*"
          style="display: none"
          type="file"
          @change="onFilePicked"
      />
      <v-dialog v-model="cropDialog" width="900" @input="cancelCrop">
        <v-card>
          <v-card-title class="headline">Crop</v-card-title>
          <v-card-text class="mt-2" style="min-height: 450px">
            <v-row>
              <v-col md="6">
                <v-card class="fill-height" flat tile>
                  <cropper
                      ref="cropper"
                      :debounce="false"
                      :height="400"
                      :src="uploadedImage"
                      :stencil-props="{
                      minAspectRatio: 1,
                      maxAspectRatio: maxAspectRatio,
                    }"
                      :width="400"
                      @change="onChange"
                  />
                </v-card>
              </v-col>
              <v-col md="6">
                <v-card class="fill-height" flat tile width="400">
                  <preview
                      :coordinates="result.coordinates"
                      :height="result.height"
                      :image="result.image"
                      :width="result.width"
                  />
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-col md="6">
              <div class="d-flex justify-space-around mt-2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        fab
                        small
                        v-bind="attrs"
                        @click="flip(false, true)"
                        v-on="on"
                    ><v-icon>mdi-flip-vertical</v-icon></v-btn
                    >
                  </template>
                  <span> Flip vertical </span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        fab
                        small
                        v-bind="attrs"
                        @click="flip(true, false)"
                        v-on="on"
                    ><v-icon>mdi-flip-horizontal</v-icon></v-btn
                    >
                  </template>
                  <span> Flip horizontal </span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        fab
                        small
                        v-bind="attrs"
                        @click="rotate(90)"
                        v-on="on"
                    ><v-icon>mdi-rotate-left</v-icon></v-btn
                    >
                  </template>
                  <span> Rotate Left </span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        fab
                        small
                        v-bind="attrs"
                        @click="rotate(-90)"
                        v-on="on"
                    ><v-icon>mdi-rotate-right</v-icon></v-btn
                    >
                  </template>
                  <span> Rotate right </span>
                </v-tooltip>
              </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-btn class="blue-color" dark text @click="cancelCrop"
            >Cancel</v-btn
            >
            <v-btn class="teal" dark text @click="closeCropper">
              <v-icon left>mdi-crop-free</v-icon>Fullsize</v-btn
            >
            <v-btn class="teal" dark text @click="cropImage">
              <v-icon left>mdi-crop</v-icon> Crop</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="chooseFileDialog" width="600" @input="chooseFileDialog = false">
      <v-card>
        <v-card-text class="pb-0">
          <div class="row pt-1 border-bottom">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon class="text-xl font-normal"
                         text="Upload or Select" style="color: black">
                </SvgIcon>
                <v-btn fab x-small class="shadow-0" @click="chooseFileDialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <div class="d-flex justify-space-between pa-4 gap-x-2 mt-4">
            <div class="d-flex align-center justify-center pointer bordered pa-4 rounded-2 choose-file-source" @click="MediaListingModal = true" >
              <SvgIcon class="font-normal gap-x-2" text="Choose from library">
                  <template #icon>
                    <ChooseIcon/>
                  </template>
                </SvgIcon>
            </div>
            <div class="d-flex align-center justify-center pointer bordered pa-4 rounded-2 choose-file-source" @click="pickImgFile" >
              <SvgIcon class="font-normal gap-x-2" text="Upload File">
                  <template #icon>
                    <UploadIcon/>
                  </template>
                </SvgIcon>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <MediaListingModal
        @close="MediaListingModal = false"
        @selectTarget="selectFileFromLibrary"
        :MediaListingModal="MediaListingModal"
    />
    </div>
  </span>
</template>

<script>
import {Cropper, Preview} from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import images from "@/utils/images";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import MediaListingModal from "@/components/MediaLibrary/MediaListingModal.vue";
import ChooseIcon from "@/assets/images/misc/upload-cloud.svg";
import UploadIcon from "@/assets/images/misc/upload-pc.svg";

export default {
  components: {
    UploadIcon, ChooseIcon, MediaListingModal, SvgIcon,
    Cropper,
    Preview,
  },
  props: {
    image_path: { default: null},
    imgIndex: {type: Number, default: null},
    bg_color: {type: String, default: "#F5F8FA"},
    aspect_ratio: {type: String, default: "1.7"},
    defaultImage: {type: String, default: "default"},
    height: {type: Number, default: 100},
    text: {type: String, default: "Click to change the image"},
    defaultBtn: {type: Boolean, default: false},
    maxAspectRatio: {type: Number, default: 1},
    isRemoveIcon: {type: Boolean, default: false},
    isB2cCarousel: {type: Boolean, default: false},
    showGuide: {type: Boolean, default: false},
    messagePosition: {type: String, default: 'outside'},
    deleteUploadedImage: {type: Boolean, default: false},
    contain:{type:Boolean, default: false},
    messageText: {type: String, default: 'Jpeg and Png images are allowed, Maximum Size 5 MB'}

  },
  data() {
    return {
      MediaListingModal:false,
      chooseFileDialog: false,
      uploadedImage: null,
      currentImage: null,
      cropDialog: false,
      result: {
        coordinates: null,
        image: null,
        height: 400,
        width: 400,
      },
      file: null,
    };
  },
  watch: {
    image() {
      this.uploadedImage = null;
    },
    image_path: {
      immediate: true,
      handler() {
        this.uploadedImage = null;
      },
    },
  },
  mounted() {
  },
  methods: {
    deleteImage(){
      this.$emit("deleteImg",this.imgIndex );
    },
    getImage() {
      if (this.uploadedImage) {
        return this.uploadedImage;
      }
      if (this.image_path && typeof this.image_path !== 'object') {
        return this.s3BucketURL + this.image_path;
      }
      if (this.image_path && typeof this.image_path === 'object') {
        return window.URL.createObjectURL(this.image_path);
      }
      return images[this.defaultImage];
    },
    // pickFile() {
    //   this.$refs.image.click();
    // },
    pickImgFile() {
      this.chooseFileDialog = false;
      this.$refs.image.click();
    },
    pickFile() {
      if(this.checkReadPermission(this.$modules.mediaLibrary.management.slug)){
        this.chooseFileDialog = true;
      }else{
        this.pickImgFile();
      }
    },
    selectFileFromLibrary(file){
      this.showLoader('loading your image');
      let filePath = this.s3BucketURL + file.path;
      this.createFileFromImageUrl(filePath, 'image.jpg').then(file => {
        this.onFilePicked({target:{files:[file]}})
        this.chooseFileDialog = false;
      }).catch(error => {
        console.error(error);
      }).finally(()=> {
        this.hideLoader();
      });
    },
    createFileFromImageUrl(imageUrl, fileName) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous'; // Ensure CORS doesn't block the image

        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');

          // Draw the image onto the canvas
          ctx.drawImage(img, 0, 0);

          // Convert the canvas content to a Blob
          canvas.toBlob(blob => {
            if (blob) {
              const file = new File([blob], fileName, { type: blob.type });
              resolve(file);
            } else {
              reject(new Error('Canvas is empty or failed to create Blob.'));
            }
          }, 'image/jpeg'); // You can specify the mime type here
        };

        img.onerror = (error) => {
          reject(new Error('Failed to load image: ' + error.message));
        };
        img.src = imageUrl; // Set the source of the image
      });
    },
    cancel() {
      if (this.defaultBtn == false) {
        this.uploadedImage = null;
        this.$emit("remove");
        this.$refs.image.value = null;
      }
    },
    cancelCrop() {
      this.cropDialog = false;
      this.resul = {
        coordinates: null,
        image: null,
      };
      this.cancel();
    },
    onFilePicked(e) {
      let files = e.target.files;
      if (!files.length) {
        return;
      }
      let reader = new FileReader();
      reader.onload = (e) => {
        this.uploadedImage = e.target.result;
        this.file = files[0];
        this.$emit("upload", files[0]);
        this.$emit("result", e.target.result);
        this.$refs.image.value = null;
        this.cropDialog = true;
      };
      reader.readAsDataURL(files[0]);
    },
    cropImage() {
      const {coordinates, canvas} = this.$refs.cropper.getResult();
      this.coordinates = coordinates;
      let imageDataUrl = canvas.toDataURL();
      this.uploadedImage = imageDataUrl;
      this.$emit("upload", this.dataURLtoFile(imageDataUrl, this.file.name));
      this.$emit("result", this.uploadedImage);
      this.$refs.image.value = null;
      this.cropDialog = false;
      this.MediaListingModal = false
    },
    closeCropper(){
      this.cropDialog = false
      this.MediaListingModal = false
    },
    onChange({coordinates, image}) {
      let ratio = coordinates.height / coordinates.width;
      this.result = {
        coordinates,
        image,
      };
      this.result.height = 400 * ratio;
      this.result.width = 400;
    },
    flip(x, y) {
      if (this.$refs.cropper.customImageTransforms.rotate % 180 !== 0) {
        this.$refs.cropper.flip(!x, !y);
      } else {
        this.$refs.cropper.flip(x, y);
      }
    },
    rotate(angle) {
      this.$refs.cropper.rotate(angle);
    },
    dataURLtoFile(dataurl, filename) {
      //Convert base64 to file
      var arr = dataurl.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {
        type: mime,
      });
    },
  },
};
</script>

<style scoped>
.img-box {

  border-radius: 0.375rem;
  border: 1px dashed #CECECE;
  background: #F5F8FA;
  width: 100%;
  min-height: 10rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .v-btn {
    border-radius: 0.375rem;
    border: 1px solid #4FAEAF;
    background: rgba(109, 170, 200, 0.1);
    color: #4FAEAF;

  }
}
.choose-file-source{
  background:#F2F2F2 !important;
  width: 50% !important;
  color: black !important;
}
</style>
