<template>
  <v-dialog v-model="showDialog" width="700" @input="closeDialog">
    <v-card>
      <v-card-title class="headline">View Image</v-card-title>
      <v-card-text class="mt-2" style="min-height: 450px">
        <v-row class="justify-center">
          <v-col cols="12" sm="6" md="6">
            <v-img
              v-if="image_path"
              :src="s3BucketURL + image_path"
              width="100%"
            ></v-img>
            <v-img v-else-if="data_url" :src="data_url"></v-img>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import "vue-advanced-cropper/dist/style.css";
export default {
  props: {
    image_path: { type: String, default: null },
    data_url: { type: String, default: null },
  },
  data() {
    return {
      showDialog: false,
      file: null,
    };
  },
  watch: {
    image_path: function () {
      this.showDialog = true;
    },
    data_url: function () {
      this.showDialog = true;
    },
  },
  methods: {
    closeDialog() {
      this.$emit("resetImagePath");
    },
  },
};
</script>

<style>
</style>