export default {
  TIMINGS: [
    {
      value: "00:00:00",
      text: "12 AM",
    },
    {
      value: "01:00:00",
      text: "1 AM",
    },
    {
      value: "02:00:00",
      text: "2 AM",
    },
    {
      value: "03:00:00",
      text: "3 AM",
    },
    {
      value: "04:00:00",
      text: "4 AM",
    },
    {
      value: "05:00:00",
      text: "5 AM",
    },
    {
      value: "06:00:00",
      text: "6 AM",
    },
    {
      value: "07:00:00",
      text: "7 AM",
    },
    {
      value: "08:00:00",
      text: "8 AM",
    },
    {
      value: "09:00:00",
      text: "9 AM",
    },
    {
      value: "10:00:00",
      text: "10 AM",
    },
    {
      value: "11:00:00",
      text: "11 AM",
    },
    {
      value: "12:00:00",
      text: "12 PM",
    },
    {
      value: "13:00:00",
      text: "1 PM",
    },
    {
      value: "14:00:00",
      text: "2 PM",
    },
    {
      value: "15:00:00",
      text: "3 PM",
    },
    {
      value: "16:00:00",
      text: "4 PM",
    },
    {
      value: "17:00:00",
      text: "5 PM",
    },
    {
      value: "18:00:00",
      text: "6 PM",
    },
    {
      value: "19:00:00",
      text: "7 PM",
    },
    {
      value: "20:00:00",
      text: "8 PM",
    },
    {
      value: "21:00:00",
      text: "9 PM",
    },
    {
      value: "22:00:00",
      text: "10 PM",
    },
    {
      value: "23:00:00",
      text: "11 PM",
    },
    {
      value: "23:59:59",
      text: "12 AM",
    },
  ],

  VIEWRESTRICTEDFIELDS: [
    "name",
    "email",
    "mobile",
    "b2b_type_id",
    "phone",
    "entity_name",
    "contact_person_name",
    "entity_name",
  ],
  MANTATORYRESTRICTEDFIELDS: [
    "name",
    "mobile",
    "email",
    "b2b_type_id",
    "phone",
    "entity_name",
    "contact_person_name",
    "entity_name",
  ],
  GL_CODES: [
    {
      value: "Retail Sales by DCT at cultural sites",
      text: "Retail Sales by DCT at cultural sites",
    },{
      value: "Festival entrance charge & other income",
      text: "Festival entrance charge & other income",
    },{
      value: "Training - General",
      text: "Training - General",
    },{
      value: "Public Land Leases",
      text: "Public Land Leases",
    }
  ],
  TASK_NAMES: [
    {
      value: "Retail Revenue",
      text: "Retail Revenue",
    },{
      value: "Admission Sites Tickets",
      text: "Admission Sites Tickets",
    },{
      value: "Admission - Workshops",
      text: "Admission - Workshops",
    },{
      value: "Admission Events Tickets",
      text: "Admission Events Tickets",
    },{
      value: "Classes and Training",
      text: "Classes and Training",
    },{
      value: "Space Rent",
      text: "Space Rent",
    },{
      value: "Other Revenue",
      text: "Other Revenue",
    }
  ],
  QportalModules:[
      'Schedule',
      'Facility',
      'Space',
      'Events',
      'Academy',
      'Trainer',
      'Customers',
      // 'Corporates',
      'Memberships',
      'B2B',
      'Sales',
      'Reports',
      'Promotions',
      'Vouchers',
      'Marketing',
      'Qube',
      'Profile'
  ],
  SUPPORT_STATUS: {
    OPEN:25,
    CLOSED:26,
    RESOLVED:27
  },
  SeveritiesLevel : {
    0: 'Unassigned',
    1: 'Level 1 (2hrs)',
    2: 'Level 2 (4hrs)',
    3: 'Level 3 (8hrs)',
    4: 'Level 4 (24hrs)',
  },
  EventTypes:{
    SingleEvent:"Single Event",
    SingleAccess:"Single Access",
    MultiAccess:"Multi Access"
  },

  ResetTrainerCommissionInterval: [
    { type: 0, name: "No" },
    { type: 1, name: "1st of every month" },
    { type: 3, name: "1st of every quarter" },
    { type: 6, name: "1st of every 6 months" },
    { type: 12, name: "1st of every year" },
  ],
  STOCK_STATUS: {
    I:'Stock In',
    S:'Sale',
    R:'Refund',
    O:'Stock Out'
  },
  sectors:['Public','Private'],
  institutionType:['school','college','university'],
};
